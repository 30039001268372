/* #region LootCard */

.spinner {
  animation: rotation 1.4s linear infinite;
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
}

.landscape {
  transform: rotateZ(90deg);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

:root {
  --color1: rgb(79, 103, 131);
  --color2: rgb(121, 94, 124);
}

.card-holo {
  width: 71.5vw;
  height: 100vw;
  position: relative;
  overflow: hidden;
  margin: 20px;
  overflow: hidden;
  z-index: 10;
  touch-action: none;
  border-radius: 5%/3.5%;
  box-shadow:
    -5px -5px 5px -5px var(--color1),
    5px 5px 5px -5px var(--color2),
    -7px -7px 10px -5px transparent,
    7px 7px 10px -5px transparent,
    0 0 5px 0px rgba(255, 255, 255, 0),
    0 55px 35px -20px rgba(0, 0, 0, 0.5);
  transition:
    transform 0.5s ease,
    box-shadow 0.2s ease;
  will-change: transform, filter;
  background-color: #040712;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;
}
.card-holo.custom {
  /* custom일땐 꺼져야됨 */
  box-shadow: 0px 0px 0px 0px;
}

@media screen and (min-width: 600px) {
  .card-holo {
    width: clamp(12.9vw, 61vh, 18vw);
    height: clamp(18vw, 85vh, 25.2vw);
  }
}

/* custom일땐 꺼져야됨 */
.card-holo:hover {
  box-shadow:
    -20px -20px 30px -25px var(--color1),
    20px 20px 30px -25px var(--color2),
    -7px -7px 10px -5px var(--color1),
    7px 7px 10px -5px var(--color2),
    0 0 13px 4px rgba(255, 255, 255, 0.3),
    0 55px 35px -20px rgba(0, 0, 0, 0.5);
}

.card-holo.custom:hover {
  /* custom일땐 꺼져야됨 */
  box-shadow: 0px 0px 0px 0px;
}

.card-holo.holographic {
  --color1: #ce6387;
  --color2: #bb9753;
  --color3: #43c783;
  --color4: #489ab3;
  --color5: #934fb3;
}

.card-holo.legendary {
  --color1: #d6ab34;
  --color2: #db2e2e;
  --color3: #e0542a;
  --color4: #d66835;
  --color5: #dbcf20;
}

.card-holo:before,
.card-holo:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  transition: all 0.33s ease;
}

.card-holo:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  opacity: 0.5;
  filter: brightness(0.5) contrast(1);
  z-index: 1;
}

.card-holo.holo:after {
  opacity: 1;
  background-image: url("../../img/sparkles.gif"), url("../../img/holo.png"),
    linear-gradient(
      125deg,
      #ff00842a 15%,
      #fca4002a 30%,
      #ffff002a 40%,
      #00ff8a2a 60%,
      #00cfff2a 70%,
      #cc4cfa2a 85%
    );
  background-position: 50% 50%;
  background-size: 160%;
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all 0.33s ease;
  mix-blend-mode: color-dodge;
  opacity: 0.75;
}

.card-holo.active:after,
.card-holo:hover:after {
  filter: brightness(1) contrast(1);
  opacity: 1;
}

.card-holo.active,
.card-holo:hover {
  -webkit-animation: none;
  animation: none;
  transition: box-shadow 0.1s ease-out;
}

.card-holo.active:before,
.card-holo:hover:before {
  -webkit-animation: none;
  animation: none;
  background-image: linear-gradient(
    110deg,
    transparent 25%,
    var(--color1) 48%,
    var(--color2) 52%,
    transparent 75%
  );
  background-position: 50% 50%;
  background-size: 250% 250%;
  opacity: 0.88;
  filter: brightness(0.66) contrast(1.33);
  transition: none;
}

.card-holo.active:before,
.card-holo:hover:before,
.card-holo.active:after,
.card-holo:hover:after {
  -webkit-animation: none;
  animation: none;
  transition: none;
}

.card-holo.animated {
  transition: none;
  -webkit-animation: holoCard 12s ease 0s 1;
  animation: holoCard 12s ease 0s 1;
}
.card-holo.landscape.animated {
  transition: none;
  -webkit-animation: holoCardLandscape 12s ease 0s 1;
  animation: holoCardLandscape 12s ease 0s 1;
}
.card-holo.animated:before {
  transition: none;
  -webkit-animation: holoGradient 12s ease 0s 1;
  animation: holoGradient 12s ease 0s 1;
}
.card-holo.animated:after {
  transition: none;
  -webkit-animation: holoSparkle 12s ease 0s 1;
  animation: holoSparkle 12s ease 0s 1;
}

@-webkit-keyframes holoSparkle {
  0%,
  100% {
    opacity: 0.75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }
  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(0.8) contrast(1.2);
  }
  13%,
  16% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(0.8);
  }
  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }
  55% {
    opacity: 0.33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes holoSparkle {
  0%,
  100% {
    opacity: 0.75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }
  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(0.8) contrast(1.2);
  }
  13%,
  16% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(0.8);
  }
  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }
  55% {
    opacity: 0.33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}
@-webkit-keyframes holoGradient {
  0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(0.5) contrast(1);
  }
  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
  13%,
  17% {
    background-position: 0% 0%;
    opacity: 0.88;
  }
  35%,
  39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
}
@keyframes holoGradient {
  0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(0.5) contrast(1);
  }
  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
  13%,
  17% {
    background-position: 0% 0%;
    opacity: 0.88;
  }
  35%,
  39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
}
@-webkit-keyframes holoCard {
  0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}
@keyframes holoCard {
  0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}

@-webkit-keyframes holoCardLandscape {
  0%,
  100% {
    transform: rotateZ(90deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(90deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(90deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(93deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(87deg) rotateX(-12deg) rotateY(-27deg);
  }
}
@keyframes holoCardLandscape {
  0%,
  100% {
    transform: rotateZ(90deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(90deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(90deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(93deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(87deg) rotateX(-12deg) rotateY(-27deg);
  }
}

.card-holo.holo:hover:before,
.card-holo.holo.active:before {
  background-image: linear-gradient(
    115deg,
    transparent 20%,
    var(--color1) 36%,
    var(--color2) 43%,
    var(--color3) 50%,
    var(--color4) 57%,
    var(--color5) 64%,
    transparent 80%
  );
}

.operator {
  display: inline-block;
  vertical-align: middle;
  font-size: 6vh;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  perspective: 2000px;
  position: relative;
  z-index: 1;
  transform: translate3d(0.1px, 0.1px, 0.1px);
}

.cards .card-holo:nth-child(2),
.cards .card-holo:nth-child(2):before,
.cards .card-holo:nth-child(2):after {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.cards .card-holo:nth-child(3),
.cards .card-holo:nth-child(3):before,
.cards .card-holo:nth-child(3):after {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.cards .card-holo:nth-child(4),
.cards .card-holo:nth-child(4):before,
.cards .card-holo:nth-child(4):after {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

@-webkit-keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

/* #endregion LootCard */
