@font-face {
  font-family: "paybooc-Bold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MaruBuri";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.eot);
  src:
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "BCcardB";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.1/BCcardB.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.cardCover * {
  font-family: "paybooc-Bold";
}

.cardCover.gothic * {
  font-family: "나눔고딕";
}

.cardCover {
  margin: 100px;
  width: 600px;
  height: 800px;
  position: relative;
}

.cardCover .cardBody {
  background: #15181d;
  background-size: cover;
  display: inline-block;
  width: 600px;
  height: 800px;
  position: relative;
}
.cardCover #cardFrame {
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
}

.cardCover .cardBody .cardTop span {
  position: absolute;
  z-index: 10;
}
.cardCover .cardBody .cardTop .servername {
  background-color: #80808038;
  border-radius: 60px;
  opacity: 1;
  font-size: 24px;
  font-weight: 900;
  padding: 12px 20px;
  line-height: 36px;
  color: #fcfcfc;
  letter-spacing: -2px;
  top: 64px;
  right: 68px;
}

.cardCover.front .cardTop .class_mark {
  background: radial-gradient(
    circle,
    rgba(21, 24, 29, 0.1) 0%,
    rgba(21, 24, 29, 0) 100%
  );
  padding: 8px 15px 8px 15px;
  border-radius: 50%;
  width: 60px;

  z-index: 10;
  position: absolute;
  top: 88px;
  left: 52px;
}
.cardCover.front .cardTop .class_mark img {
  width: 72px;
}

.cardCover.back .cardBody .cardTop span {
  top: 36px;
  right: 36px;
}
.cardCover.back .cardBody .cardTop #back_logo {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 60px;
}
.cardCover.back .cardBody .cardTop h3 {
  color: #fff;
  font-weight: 800;
  text-shadow: 2px 3px 4px #15181d;

  font-size: 30px;
  letter-spacing: -2px;
  text-align: left;
  margin-top: 32px;
  margin-left: 88px;
}

.cardMiddle {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  padding: 0 40px;
}

.cardMiddle .options {
  display: flex;
  flex-wrap: wrap;
}

.cardCover.back .cardMiddle {
  padding-top: 40px;
  height: 80%;
}

.cardMiddle .cardImgs {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardMiddle .cardImgs .cardCharacter {
  width: 100%;
  height: 100%;
  /* background-image: url(${imgRef.current}); */
  background-repeat: no-repeat;
  opacity: 1;
  background-position-x: -40px;
  background-position-y: 40px;
}

.cardMiddle .cardImgs .cardCharacter.aeromancer,
.cardMiddle .cardImgs .cardCharacter.artist {
  background-position-y: -40px;
}
.cardMiddle .cardImgs .cardCharacter.demonic {
  background-position-x: -72px;
  background-position-y: 38px;
}
.cardMiddle .cardImgs .cardCharacter.soulEater {
  background-position-x: 0px;
  background-position-y: 30px;
}
.cardMiddle .cardImgs .cardCharacter.slayer {
  background-position-x: -25px;
}

.cardCover .cardMiddle .engravings {
  position: absolute;
  top: 144px;
  right: 60px;
}
.cardMiddle .engravings .engraving_item {
  position: relative;
  width: 48px;
}
.cardMiddle .engravings .engraving_item img {
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  position: relative;
}
.cardMiddle .engravings .engraving_grade {
  position: absolute;
  top: -2px;
  right: -4px;
  width: 24px;
  height: 52px;
  border-radius: 0 52px 52px 0;
  opacity: 0.8;
  z-index: 1;
  overflow: hidden;
}
.cardMiddle .engravings .engraving_grade div {
  width: 100%;
  height: 100%;
  transform-origin: bottom;
}

.cardMiddle .engravings .engraving.empty {
  width: 48px;
  height: 48px;
  background-color: rgb(18, 18, 20);
  border-radius: 50%;
  margin-bottom: 8px;
}

.cardCover.back .cardMiddle .options_left .option_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  flex-direction: row;
  justify-content: center;
}
.cardCover.back .cardMiddle .options_left .option_item img {
  width: 40px;
  height: 40px;
  margin-left: 16px;
}
.cardCover.back .cardMiddle .options_left .option_item div {
  /* display: inline-block; */
  text-align: center;
  font-size: 22px;
}

.cardCover.back .cardMiddle .options_left .option_box {
  background-color: #80808038;
  border-radius: 6px;
  margin: 16px 8px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 700;
  height: 208px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.cardCover.back .cardMiddle .options_left .option_box .option_item.gridTwo {
  grid-column-start: 1;
  grid-column-end: 3;
}
.cardCover.back .cardMiddle .options_left .option_box .option_item.icon {
  justify-content: flex-start;
}
.cardCover.back .cardMiddle .options_left .option_box .option_item.icon > div {
  margin-left: 16px;
  font-size: 20px;
}

.cardCover.back .cardMiddle .options_left .stats {
  font-size: 22px;
  font-weight: 700;
}
.cardCover.back .cardMiddle .options_left .stats .back_stats_bg {
  background-image: url("../../../public/assets/images/icons/card_back_stat_bg.png");
  background-size: cover;
  /* background-repeat: no-repeat; */
  height: 37px;
  width: 99%;
  margin-bottom: 8px;
  margin-left: 12px;
  padding: 2px 0px 1px 28px;
  justify-content: center;
  line-height: 32px;
}

.cardCover.back
  .cardMiddle
  .options_left
  .stats
  .back_stats_bg
  span:first-child {
  float: left;
}
.cardCover.back
  .cardMiddle
  .options_left
  .stats
  .back_stats_bg
  .back_guildname {
  letter-spacing: -1.8px;
  font-size: 20px;
}

.cardCover.back .cardMiddle .options_left .engravings {
  position: static;
  margin-top: 6px;
}

.cardCover.back .cardMiddle .options_left .engravings ul {
  display: inline-flex;
}
.cardCover.back .cardMiddle .options_left .engravings ul li {
  margin-right: 4px;
}
.cardCover.back .cardMiddle .options_right {
  padding: 12px;
}

.cardCover.back .cardMiddle .options_right .equipments ul li,
.cardCover.back .cardMiddle .options_right .accessory ul li {
  margin-bottom: 8px;
  position: relative;
  height: 75px;
}

.cardCover.back .cardMiddle .options_right .equipments ul li .equipments_badge,
.cardCover.back
  .cardMiddle
  .options_right
  .equipments
  ul
  li
  .equipment_high_badge,
.cardCover.back .cardMiddle .options_right .accessory ul li .accessory_badge {
  position: absolute;
  border-radius: 20px;
  padding: 0px 5px 2px 5px;
  line-height: 022px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.8px;
  color: #1d1e21;
}
.cardCover.back .cardMiddle .options_right .accessory ul li span {
  position: absolute;
}
.cardCover.back
  .cardMiddle
  .options_right
  .accessory
  ul
  li
  .accessory_badge:nth-child(2) {
  top: 24px;
}

.cardCover.back
  .cardMiddle
  .options_right
  .accessory
  ul
  li
  .accessory_up_badge:nth-child(2) {
  top: 14px;
}
.cardCover.back
  .cardMiddle
  .options_right
  .accessory
  ul
  li
  .accessory_up_badge:nth-child(3) {
  top: 28px;
}

.cardCover.back .cardMiddle .options_right .equipments ul li .equipments_badge {
  right: -22px;
}

.cardCover.back .cardMiddle .options_right .accessory ul li .accessory_badge {
  right: -14px;
}
.cardCover.back
  .cardMiddle
  .options_right
  .equipments
  ul
  li
  .equipment_high_badge {
  right: 42px;
  top: 28px;
}
.cardCover.back
  .cardMiddle
  .options_right
  .equipments
  ul
  li
  #back_equipment_high {
  width: 46px;
  position: absolute;
  top: 44px;
  left: -18px;
}
#back_equipment_trans {
  width: 48px;
  height: 36px;
  position: absolute;
  top: 40px;
  left: 40px;
}
.cardCover.back .cardMiddle .options_right .equipments ul li div,
.cardCover.back .cardMiddle .options_right .accessory ul li div {
  background-color: #82786e;
  width: 100%;
  height: 8px;
}
.cardCover.back
  .cardMiddle
  .options_right
  .equipments
  ul
  li
  div
  .back_equipment_quality,
.cardCover.back
  .cardMiddle
  .options_right
  .accessory
  ul
  li
  div
  .back_accessory_quality {
  /* background-color: orange; */
  /* width: 80%; */
  height: 8px;
}

.cardMiddle .title {
  background: linear-gradient(
    0deg,
    rgba(71, 67, 67, 0.7) 40%,
    rgba(70, 69, 69, 0) 100%
  );
  width: 100%;
  height: 400px;
  position: absolute;
  bottom: 0;
  left: 0px;
  align-items: center;
  text-align: center;
  padding-top: 72px;
}

.cardMiddle .title h2 {
  color: #f7f7f7;
  font-weight: 800;
  text-shadow: 2px 3px 4px #15181d;

  font-size: 40px;
  letter-spacing: -2px;
  /* padding-right: 16px; */
}
.cardMiddle .title .titlename h2 {
  font-size: 24px;
}
.cardMiddle .title .nickname {
  margin-bottom: 20px;
}
.cardMiddle .title .nickname h2 {
  font-size: 40px;
}
.cardMiddle .title .lvl {
  display: flex;
  justify-content: center;
  margin-top: -21px;
  text-shadow: 2px 3px 4px #15181d;
  font-size: 24px;
  font-weight: 600;
  margin-right: -12px;
}
.cardMiddle .title .lvl .equipments_lvl {
  color: #fff;
  display: flex;
  padding: 0 10px;
  margin-left: -16px;
}
.cardMiddle sub {
  /* font-size: 12px; */
  line-height: 32px;
}
.cardMiddle .title .lvl .expedition_lvl {
  color: #fff;
  display: flex;
  padding: 0 10px;
  margin-left: -8px;
}
.cardMiddle .title .info {
  margin-top: 10px;
}
/* .cardMiddle .info_table_tr {
  font-size: 20px;
} */
.cardMiddle .title .info .info_table {
  color: #fff;
  text-shadow: 2px 3px 4px #15181d;
  font-size: 20px;
  padding: 0 48px;
}
.cardMiddle .title .info .info_table .info_table_tr {
  padding-right: 5px;
  padding-left: 5px;
}

.cardMiddle .title .info .info_table .info_table_td {
  font-size: 24px;
  font-weight: 550;
  letter-spacing: -2.3px;
}
.cardMiddle .title .info_icons {
  margin-top: 12px;
  /* width: 85%; */
  height: 80px;
}
.cardMiddle .title .info_icons .icons_table {
  color: #fff;
  overflow: hidden;
  height: 100%;
}

.cardMiddle .title .info_icons .icons_table .icons_table_tr {
  border-radius: 30%;
  /* background: radial-gradient(
    circle,
    rgba(99, 99, 29, 1) 50%,
    rgba(21, 24, 29, 0) 100%
  ); */
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}

.cardCover.front .icon_img_cover {
  height: 64px;
  overflow: hidden;
}

.cardCover.front .icon_img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.cardCover.front .icon_img.mococo {
  width: 774px;
  height: 1000px;
  background-position-x: -702px;
  background-position-y: -276px;
}
.cardCover.front .icon_img.islandSoul {
  width: 774px;
  height: 1000px;
  background-position-x: -714px;
  background-position-y: -76px;
}
.cardCover.front .icon_img.masterpiece {
  width: 774px;
  height: 1000px;
  background-position-x: -544px;
  background-position-y: -520px;
}
.cardCover.front .icon_img.giantHeart {
  width: 774px;
  height: 1000px;
  background-position-x: -616px;
  background-position-y: -516px;
}
.cardCover.front .icon_img.igneaToken {
  width: 774px;
  height: 1000px;
  background-position-x: -710px;
  background-position-y: 0px;
}
.cardCover.front .icon_img.adventure {
  width: 774px;
  height: 1000px;
  background-position-x: -702px;
  background-position-y: -342px;
}
.cardCover.front .icon_img.leaf {
  width: 774px;
  height: 1000px;
  background-position-x: -706px;
  background-position-y: -142px;
}
.cardCover.front .icon_img.orpeus {
  width: 774px;
  height: 1000px;
  background-position-x: -706px;
  background-position-y: -210px;
}
.cardCover.front .icon_img.orgel {
  width: 774px;
  height: 1000px;
  background-position-x: 6px;
  background-position-y: -650px;
}

.cardCover.back .icon_img_cover {
  height: 40px;
  width: 40px;
  overflow: hidden;
}

.cardCover.back .icon_img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}
.cardCover.back .icon_img.mococo {
  width: 483px;
  height: 1000px;
  background-position-x: -438px;
  background-position-y: -172px;
}

.cardCover.back .icon_img.islandSoul {
  width: 483px;
  height: 1000px;
  background-position-x: -442px;
  background-position-y: -44px;
}

.cardCover.back .icon_img.masterpiece {
  width: 450px;
  height: 1000px;
  background-position-x: -316px;
  background-position-y: -296px;
}

.cardCover.back .icon_img.giantHeart {
  width: 483px;
  height: 1000px;
  background-position-x: -386px;
  background-position-y: -320px;
}

.cardCover.back .icon_img.igneaToken {
  width: 483px;
  height: 1000px;
  background-position-x: -440px;
  background-position-y: 0px;
}

.cardCover.back .icon_img.adventure {
  width: 450px;
  height: 1000px;
  background-position-x: -408px;
  background-position-y: -198px;
}
.cardCover.back .icon_img.leaf {
  width: 483px;
  height: 1000px;
  background-position-x: -440px;
  background-position-y: -90px;
}
.cardCover.back .icon_img.orpeus {
  width: 483px;
  height: 1000px;
  background-position-x: -440px;
  background-position-y: -130px;
}
.cardCover.back .icon_img.orgel {
  width: 483px;
  height: 1000px;
  background-position-x: 4px;
  background-position-y: -406px;
}

.cardMiddle .title .info_icons .icons_table .icons_table_tr .icons_table_badge {
  border-radius: 20px;
  line-height: 022px;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -0.8px;

  position: absolute;
  bottom: 10px;
  right: 0px;

  background-color: #d73737;
  overflow: hidden;
}

.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right-10 {
  right: -10px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right-15 {
  right: -15px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right-20 {
  right: -20px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right-30 {
  right: -30px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right-45 {
  right: -45px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right--5 {
  right: +5px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right--10 {
  right: +10px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right--20 {
  right: +20px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right--25 {
  right: +25px;
}
.cardMiddle
  .title
  .info_icons
  .icons_table
  .icons_table_tr
  .icons_table_badge.right--30 {
  right: +30px;
}

.cardCover.back .cardBottom .card_awakename {
  color: #fff;
  background: #80808038;

  font-size: 24px;
  font-weight: 900;
  letter-spacing: -2px;

  position: absolute;
  left: 5%;
  bottom: 176px;

  border-radius: 8px;
  padding: 7px 16px;
}
.cardCover.back .cardBottom .card_container {
  width: 90%;
  padding: 10px 8px;
  background: #80808038;
  border-radius: 8px;
  position: absolute;
  left: 5%;
  bottom: 32px;
  display: flex;
  align-items: flex-end;
}
.cardCover.back .cardBottom .card_container .card_slot {
  padding: 0 4px;
  position: relative;
}
.cardCover.back .cardBottom .card_container .card_slot .card_grade {
  position: absolute;
  width: 93%;
  height: 100%;
}
.cardCover.back .cardBottom .card_container .card_slot .card_awake {
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 6%;
}
.cardCover.back .cardBottom .card_container .card_slot img:nth-child(3) {
  padding: 4px 1px 4px 2px;
}

#card_none {
  width: 79px;
  height: 116px;
  border: 1px #fff;
}

@media (min-width: 576px) {
  .card_container,
  .info,
  .cardMiddle {
    max-width: 100%;
  }
}
